<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header justify-content-between">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input
							v-model.trim="search"
							class="form-control list-search"
							type="search"
							placeholder="Search"
							/>
						<span class="input-group-text border-0">
							<i class="fe fe-search"></i>
						</span>
					</div>
					<div class="">
						<download-button
							v-if="allAttendees.length"
							:downloading="downloadingReport"
							label="Download Report"
							@download="downloadReport()"
							></download-button>
					</div>
				</div>
				<div class="col px-0 d-flex my-3">
					<div class="col"></div>
					<div class="col-auto">
						<div class="col-auto" v-if="allAttendees.length">
							<v-datepicker
								style="width: 100%"
								v-model="dateRangeFilter"
								placeholder="Today"
								range
								></v-datepicker>
						</div>
					</div>
				</div>
				<b-table
					striped
					hover
					selectable
					responsive
					:items="allAttendees"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingEvents"
					@row-clicked="viewAttendee"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(user)="data">
						<p class="font-weight-bold my-0" style="line-height: 1">
							<router-link
								class="sh-page-link"
								:to="{
									name: 'ShowAcceptedAttendee',
									params: { attendeeId: data.item.id },
								}"
								>
								<span>{{
									`${data.item.firstName} ${data.item.lastName}`
								}}</span>
							</router-link>
						</p>
					</template>
					<template #cell(phoneNumber)="data">
						{{ data.item.phoneNumber ? data.item.phoneNumber : 'N/A' }}
					</template>
					<template #cell(email)="data">
						<p style="line-height: 1">
							<router-link
								class="sh-page-link"
								:to="{
									name: 'ShowAcceptedAttendee',
									params: { attendeeId: data.item.id },
								}"
								>{{ data.item.email }}</router-link
								>
						</p>
						<div v-if="data.item.status === 'notified'" class="notified">
							<router-link
								class="sh-page-link"
								:to="{
									name: 'ShowAcceptedAttendee',
									params: { attendeeId: data.item.id },
								}"
								>
								<span class="email-status">
									<img src="@/assets/img/notified.svg" />
									<span>Notified</span>
								</span>
							</router-link>
						</div>
						<div v-if="data.item.status === 'pending'" class="pending">
							<router-link
								class="sh-page-link"
								:to="{
									name: 'ShowAcceptedAttendee',
									params: { attendeeId: data.item.id },
								}"
								>
								<span class="email-status">
									<img src="@/assets/img/pending.svg" />
									<span class="text">Pending</span>
								</span>
							</router-link>
						</div>
					</template>

					<template #cell(busStop)="data">
						<p class="text my-0">
							{{ data.item.pickupLocation }}
						</p>
					</template>
					<template #cell(attendingDays)="data">
						<div class="flex flex-wrap">
							<p
								v-for="(day, index) in getDays(
									`${data.item.tripDate}`,
									`${data.item.returnDate}`
								)"
								class="text my-0 mx-1"
								>
								{{
									index ===
										getDays(`${data.item.tripDate}`, `${data.item.returnDate}`)
											.length -
										1
										? formatToWeekDay(day)
										: formatToWeekDay(day) + ','
								}}
							</p>
						</div>
					</template>
				</b-table>

				<div
					class="no-results"
					v-if="allAttendees.length === 0 && eventFetchSuccess"
					>
					<img src="@/assets/img/void.svg" />
					<p>No results found!</p>
				</div>
			</div>
		</div>
		<div class="pageFooter" v-if="!fetchingEvents">
			<section>
				<p class="desc">
					Showing {{ paginationStartIndex }} - {{ paginationEndIndex }} of
					{{ totalRecords }}
				</p>
			</section>
			<section>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRecords"
					:per-page="perPage"
					></b-pagination>
			</section>
		</div>
	</div>
</template>

<script>
import Vue, { reactive } from 'vue'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import { getDatesInRange } from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton.vue'
import _ from 'lodash'
import eventServiceInstance from '@/api/event_service'
import Pagination from '@/views/Events/List/Pagination.vue'
import { format } from 'date-fns'

export default {
  components: {
    DownloadButton,
    Pagination,
  },
  props: {
    eventRequestHandler: {
      type: Vue,
      required: false,
    },
  },
  data() {
    return {
      page: 1,
      metadata: {},
      fields: [
        {
          key: 'user',
          label: 'Attendee Name',
        },
        {
          key: 'phoneNumber',
          label: 'Phone number',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'busStop',
          label: 'Nearest Bus stop',
        },
        {
          key: 'attendingDays',
          label: 'Program days',
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      pageSize: 0,
      anEvent: reactive({
        id: '',
        title: '',
        description: '',
        location: '',
        payment: '',
        returnTrip: false,
        tripDate: null,
        returnDate: null,
        attendeesEstimate: 0,
        type: '',
        banner: '',
        status: '',
        eventLink: null,
        routeAssigned: false,
        eventRouteLink: null
      }),
      loading: false,
      fetchingEvents: true,
      search: '',
      attendeesNotificationLoading: false,
      statusFilter: 'active',
      downloadingReport: false,
      eventFetchSuccess: false,
      dateRangeFilter: [],
      filter: {
        dateRange: [],
      },
    }
  },
  created() {
    this.eventRequestHandler.$on('notifyAttendees', () => {
      this.notifyAttendees()
    })

    this.initEvent()
    this.debouncedCallback = _.debounce(() => {
      this.currentPage = 1
      this.fetchAllAttendees()
    }, 1000)

    this.$store.dispatch('events/setAttendeeList', [])
  },
  computed: {
    allAttendees() {
      return this.$store.state.events.attendeeList
    },

    paginationStartIndex() {
      return this.currentPage * this.perPage - (this.perPage - 1)
    },

    paginationEndIndex() {
      return this.paginationStartIndex - 1 + this.pageSize
    }
  },
  emits: [
    'update:eventStatus',
    'update:routeAssigned',
    'update:attendeesNotificationLoading',
  ],
  watch: {
    currentPage() {
      this.initEvent()
    },
    search() {
      this.debouncedCallback()
    },
    statusFilter() {
      this.fetchAllAttendees()
    },
    dateRangeFilter() {
      this.fetchAllAttendees()
    },
  },
  methods: {
    async initEvent() {
      this.loading = true
      //   this.errorLoading = false
      try {
        const res = await eventServiceInstance.getSingleEvent(
          this.$route.params.eventId
        )
        if ([200, 201].includes(res.status)) {
          this.anEvent = res.data.data
          this.fetchAllAttendees()
          this.$emit('update:eventStatus', this.anEvent.status)
          this.$emit('update:routeAssigned', this.anEvent.routeAssigned)
        }
      } catch (error) {
        const msg =
          error.response && error.response.data
            ? error.response.data.message
            : 'An error occured, please try again.'
        this.$swal().showValidationMessage(msg)
      }
    },

    formatDate(d) {
      return moment(d).format('YYYY-MM-DD')
    },

    getDays(startDate, endDate) {
      return getDatesInRange(startDate, endDate)
    },

    formatToWeekDay(day) {
      return format(new Date(day), 'EEEE')
    },
    
    async fetchAllAttendees() {
      this.fetchingEvents = true
      try {
        const res = await eventServiceInstance.getAllAttendees(
          this.$route.params.eventId,
          this.currentPage,
          this.perPage,
          this.formatDate(this.dateRangeFilter[0]),
          this.formatDate(this.dateRangeFilter[1]),
          this.search,
          undefined
        )

        if ([200, 201].includes(res.status)) {
          this.eventFetchSuccess = true
          this.metadata = res.data.metadata
          this.totalRecords = res.data.metadata.count
          this.pageSize = res.data.metadata.pageSize
          this.$store.dispatch('events/setAttendeeList', res.data.data)
        }
      } catch (e) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
        this.fetchingEvents = false
      }
    },

    async notifyAttendees() {
      this.$emit('update:attendeesNotificationLoading', true)

      try {
        const res = await eventServiceInstance.notifyAttendees(this.anEvent.id)
        if ([200, 201].includes(res.status)) {
          this.$bvToast.show('attendee-route-notification')
          this.$bvModal.hide('notify-attendees-modal')
          this.fetchAllAttendees()
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      } finally {
        this.$emit('update:attendeesNotificationLoading', false)
      }
    },

    async downloadReport() {
      this.downloadingReport = true
      this.perPage = 100000
      await this.fetchAllAttendees()
      const csvData = this.allAttendees.map((data) => {
        return {
          name: data.firstName + ' ' + data.lastName,
          phone_number: data.phoneNumber,
          pickupLocation: data.pickupLocation,
          returnLocation: data.returnLocation,
          status: data.status,
          programDays: this.getDays(data.tripDate, data.returnDate).join(', ')
          

        }
      })
      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRangeFilter.length
          ? 'Attendee list'
          : `Attendee list from ${this.dateRangeFilter[0]}  to ${this.dateRangeFilter[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRangeFilter.length
          ? 'Attendee list'
          : `Attendee list from ${this.dateRangeFilter[0]}  to ${this.dateRangeFilter[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Phone Number',
          'Pickup Location',
          'Return Location',
          'Status',
          'Program Days'
        ],
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
        this.perPage = 10
      await this.fetchAllAttendees()
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true,
      })
    },

    viewAttendee(attendee) {
      this.$router.push({
        name: 'ShowAcceptedAttendee',
        params: {
          eventId: this.$route.params.eventId,
          attendeeId: attendee.id,
        },
      })
    }
  },
  beforeDestroy() {
    this.eventRequestHandler.$off('notifyAttendees')
    this.debouncedCallback.cancel()
  },
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.status {
  display: flex;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}
.status-accepted {
  background: #0dac5c;
}
.status-pending {
  background: #cbaf02;
}
.status-queried {
  background: #4848ed;
}
.status-rejected {
  background: #c6c8c7;
}
.pending {
  color: #e29a3b;
  // background: #4848ed;
  // margin: 5px;
}
.email-status {
  display: flex;
  gap: 5px;
}
.notified {
  color: #0dac5c;
}
.locate {
  width: 184px;
  height: 42px;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.route {
  padding: 0 0.25rem;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  max-width: 100%;

  &__source {
    position: relative;
    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 1.25rem;
      left: 0.28125rem;
      height: 70%;
      content: '';
      z-index: 0;
    }
  }
  &__source,
  &__destination {
    display: flex;
    flex-wrap: nowrap;
    color: #313533;
    .icon {
      margin-right: 0.5rem;
      width: 0.8rem;
      z-index: 2;
    }
    .text {
      /*      display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;*/
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 1.5rem 0;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      //  Container has to have a max-width attribute
    }
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;

  gap: 30px;
  img {
    height: 180px;
  }
}
.pageFooter {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 94px;

  & .desc {
    font-size: 14px;
    font-weight: 500;
    color: #131615;
  }
}
</style>
